"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./../sass/index.scss";

// tiny slider
import { tns } from "tiny-slider/src/tiny-slider";

//menu
//MENU OPEN
var opener = document.getElementById("collapseToggle");
if (opener) {
	opener.onclick = function () {
		menuManipulate();
	};

	var submenuCloserButton = document.getElementsByClassName("submenuS");

	for (var i = 0; i < submenuCloserButton.length; i++) {
		submenuCloserButton[i].addEventListener("click", menuClose, false);
	}
}

function menuManipulate() {
	const openQ = document.querySelector("#collapse");
	if (openQ.classList.contains("open")) {
		//console.log("otevřeno");
		openQ.classList.remove("open");
	} else {
		//console.log("zavřeno");
		openQ.classList.add("open");
	}
}

function menuClose() {
	const openQ = document.querySelector("#collapse");
	openQ.classList.remove("open");
}
window.onload = function () {
	var tnsTest = document.getElementById("HPCarousel");
	if (tnsTest) {
		var slider = tns({
			container: "#hpslider",
			items: 1,
			center: true,
			controls: true,
			nav: false,
			mouseDrag: true,
			fixedWidth: false,
			autoHeight: true,
			slideBy: "1",
			autoplay: true,
			speed: 2000,
			autoplayButton: false,
			autoplayTimeout: 5000,
		});
	}
};

//LIGHTBOX OPEN
var lightboxBackcolor = "rgba(99, 22, 75, 0.8)";
var lightboxOpener = document.getElementsByClassName("lightboxOpener");
for (var i = 0; i < lightboxOpener.length; i++) {
	lightboxOpener[i].addEventListener("click", openLightbox, false);
}

function openLightbox(e) {
	e.preventDefault();
	var LB = document.getElementById(this.getAttribute("data-lightbox"));
	lightboxBackcolor = this.getAttribute("data-color");
	UIkit.lightbox(LB, "");
}

UIkit.util.on(document, "shown", ".uk-lightbox.uk-open", function (e) {
	//console.log("ok LB / " + lightboxBackcolor);
	var lightBoxBack = document.getElementsByClassName("uk-lightbox");
	for (var i = 0; i < lightBoxBack.length; i++) {
		lightBoxBack[i].style.backgroundColor = "rgba(" + lightboxBackcolor + ")";
	}
});

//FADEIN BOX
var startBlackBox = document.getElementById("startBlackBox");
// Code for Chrome, Safari and Opera
startBlackBox.addEventListener("webkitAnimationEnd", startBlackFadeOut);
// Standard syntax
startBlackBox.addEventListener("animationend", startBlackFadeOut);
function startBlackFadeOut() {
	startBlackBox.remove();
}

//FADEOUT LINK
var fadeOutOpener = document.getElementsByClassName("fadeOutOpener");
var fadeOutLink = "";
for (var i = 0; i < fadeOutOpener.length; i++) {
	fadeOutOpener[i].addEventListener("click", fadeOutRun, false);
}
function fadeOutRun(e) {
	e.preventDefault();
	//fadeOutLink = window.location.origin + "/peme/" + this.getAttribute("href");
	fadeOutLink = window.location.origin + "/" + this.getAttribute("href");

	//console.log("kliknuto - " + fadeOutLink);
	var leaveBlackBox = document.getElementById("leaveBlackBox");
	leaveBlackBox.addEventListener("webkitAnimationEnd", fadeOutLeave);
	leaveBlackBox.addEventListener("animationend", fadeOutLeave);
	leaveBlackBox.classList.add("leaveBlackBox");
}
function fadeOutLeave() {
	console.log("konec animace - " + fadeOutLink);
	window.location.href = fadeOutLink;
}
//PARALAX
window.addEventListener("scroll", (event) => {
	paralax();
});
window.addEventListener("load", (event) => {
	paralax();
});

function paralax() {
	var item = document.getElementsByClassName("paraX");
	for (var i = 0; i < item.length; i++) {
		if (!item.item(i).classList.contains("paraXruned")) {
			//console.log(item.item(i).dataset.paralax);

			var y = window.pageYOffset - item.item(i).offsetTop + window.innerHeight;
			var breakY = 100;
			if (window.innerHeight < 650) breakY = 50;
			if (y > breakY) {
				item.item(i).classList.add("paraXruned");
				item.item(i).classList.add(item.item(i).dataset.paralax);
			}
		}
	}
}
